import React from 'react';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import { getMetaData } from '../utils/utils';

const FrontPage = ({ pageContext }) => {
	const { modules } = pageContext;
	const meta  = getMetaData(pageContext);

	return (
		<>
			<Meta {...meta}/>
			<ModulesController modules={modules} />
		</>
	);
};

export default FrontPage;